import * as React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import styled from "styled-components";

import {
  Layout,
  Section,
  Heading,
  Footer,
  FooterItem,
  SubSubHeading,
  WideContent,
  Header,
  Badge,
  BadgeContainer,
  BadgePlatform,
  SEO,
} from "../components";

import PhoneImage from "../images/phone.png";

const AppTitle = styled(Heading)`
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 4rem;
  margin-top: 8rem;

  @media only screen and (max-width: 300px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 0;
    text-align: center;
  }
`;

const AppFeatures = styled.ul`
  padding-left: 20px;
  margin-bottom: 50px;

  li {
    color: #757575;
    font-size: 1.3rem;
    line-height: 1.75rem;
    padding: 10px 0px;
  }
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;

  ${Section}:first-child {
    flex: 60;
  }

  ${Section}:last-child {
    flex: 40;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
`;

const PhoneImg = styled.img`
  max-width: 100%;
`;

const AppStoresPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("appstores.title")} />
      <WideContent>
        <Header />
        <Columns>
          <Section>
            <AppTitle>{t("appstores.title")}</AppTitle>
            <AppFeatures>
              <li>{t("appstores.feature_track")}</li>
              <li>{t("appstores.feature_stay_in_touch")}</li>
              <li>{t("appstores.feature_activity_data")}</li>
            </AppFeatures>
            <BadgeContainer>
              <Badge platform={BadgePlatform.Apple} />
              <Badge platform={BadgePlatform.Google} />
            </BadgeContainer>
          </Section>
          <Section>
            <PhoneImg src={PhoneImage} alt={t("appstores.screenshot_alt")} />
          </Section>
        </Columns>
      </WideContent>
      <Footer>
        <FooterItem>
          <SubSubHeading>{t("home.kaiku_health_header")}</SubSubHeading>
          <Trans
            i18nKey="home.kaiku_health_description"
            components={[<a href="https://kaikuhealth.com">Kaiku Health</a>]}
          />
        </FooterItem>
        <FooterItem>
          <SubSubHeading>{t("home.technical_support_header")}</SubSubHeading>
          <p>
            {t("home.technical_support_description")}{" "}
            <a href="mailto:support+app@kaikuhealth.com">
              support@kaikuhealth.com
            </a>
          </p>
        </FooterItem>
        <FooterItem>
          <SubSubHeading>{t("home.privacy_header")}</SubSubHeading>
          <Trans
            i18nKey="home.privacy_description"
            components={[
              <Link language="en" to="/global-privacy-policy/">
                privacy policy
              </Link>,
              <Link language="en" to="/us-privacy-policy/">
                here
              </Link>,
            ]}
          />
        </FooterItem>
      </Footer>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export function Head() {
  return (
    <meta name="google-site-verification" content="ZzP2V9h1qxRhSOIIdLmKQsAvLkSJ8bzQrNJ7Eixkp4I" />
  )
}

export default AppStoresPage;
